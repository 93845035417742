

.block{
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    vertical-align: middle;
}

.upper{
    display: flex;
    align-content: center;
    align-items: center;
    padding: 0.3em;
    font-size: .75em;
    font-weight: 800;
    height: 70%;
    min-width: 1.6rem;
    width: auto;
    justify-content: center;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 50rem;
    background-color: var(--ocean);
    position: absolute;
    right: 0;
    top: 5px;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;

    &__hide{
        display: none;
    }
}

.square{
    border-radius: var(--button-border-radius);
    background-color: var(--terracotta);
    text-transform: uppercase;
    color: var(--white);   
    font-size: 1.3rem;
    padding: 0.5rem;
}

.tag{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 1.9rem;
    position: relative;
    padding: 0.5rem 1rem;
    box-shadow: var(--box-shadow);

    &::before {
        content: "";
        position: absolute;
        display: block;
        right: -1.5rem;
        top: 0;
        width: 0;
        height: 0;
        border-top: 1.5rem solid transparent;
        border-bottom: 1.5rem solid transparent;
        border-left: 1.5rem solid var(--terracotta);
    }
    
    /* Makes the circle */
    &::after {
        content: "";
        background-color: white;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        display: block;
        position: absolute;
        right: -7px;
        top: 12px;
    }
}


.vertical{
    font-size: 1.4rem;
    color: #2E303B;
    writing-mode: vertical-lr;
    text-orientation: upright;
    text-transform: uppercase;
    padding: 8px;
}

.color{
    &__callout{
        background-color: var(--terracotta);
    }
    
    &__standard{
        background-color: var(--ocean);
    }
    &__ice{
        background-color: var(--ice);
    }
}

.inline{
    position: static;
    display: flex;
    align-items: center;

    & .upper{
        position: static;
        top: auto;
        right: auto;
        transform: none;
    }
}

